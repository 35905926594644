import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import './templates.css'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const { sectionHero } = frontmatter

  return (
    <Layout>
      <div style={{ width: '100%' }}>
        <div className="featureContainer">
          <div className="sectionHero">
            <h3>{sectionHero && sectionHero.strapline}</h3>
            <h1>{sectionHero && sectionHero.title}</h1>
            <p className="sectionHeroDescription">
              {sectionHero && sectionHero.text}
            </p>
            <br />
            <p>{sectionHero && sectionHero.emailInfo}</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        sectionHero {
          title
          strapline
          text
        }
      }
    }
  }
`
